<template>
    <label class="checkbox text-[15px] text-gray">
        <slot></slot>
        <input type="checkbox" :checked="modelValue" @change="updateValue(($event.target as HTMLInputElement).checked)">
        <span class="checkmark"></span>
    </label>
</template>

<script setup lang="ts">
defineProps({
    label: String,
    modelValue: Boolean
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
}>();

const updateValue = (value: boolean) => {
    emit('update:modelValue', value);
};
</script>

<style scoped>
.checkbox {
    display: block;
    position: relative;
    padding-left: 29px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover input~.checkmark {
        background-color: #dadada;
    }

    .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked~.checkmark {
        background-color: theme('colors.darkblue')
    }
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after {
    display: block;
}
</style>