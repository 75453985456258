<template>
    <div class="relative cursor-pointer">
        <input type="text" v-model="searchInput" @click="openDropdown" @input="search" ref="input"
            class="w-full h-[26px] max-w-lg rounded bg-grayxlight text-gray focus:bg-white focus:border-violet focus:outline-violet hover:border-gray border border-solid border-grayxlight px-3 pl-8">
        <icon class="absolute left-2 top-[6px]">
            <IconMapPin />
        </icon>
        <div v-if="isOpen"
            class="absolute top-full left-0 bg-white w-[639px] p-4 overflow-y-auto z-50 shadow-lg rounded"
            v-click-outside="closeDropdown">
            <TreeNode v-for="dep in deps" :node="dep" :key="dep.id" @node-clicked="selectDep" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from '../../stores/store'
import IconMapPin from '../../ui/icons/IconMapPin.vue'
import TreeNode from './TreeNode.vue'

const store = useStore()

const isOpen = ref(false)
const department = ref()
const departments = ref()
const deps = ref()
const searchInput = ref("")
const input = ref(null)

const openDropdown = () => {
    isOpen.value = true
    searchInput.value = ""
};

const closeDropdown = () => {
    isOpen.value = false
    searchInput.value = department.value.name
    input.value.blur()
    resetTree()
};

const resetTree = () => {
    deps.value = [...departments.value]
}

const selectDep = (dep) => {
    department.value = dep
    searchInput.value = department.value.name
    store.setCurrentDepartment(department.value)
    store.fetchSchedule()
    isOpen.value = false
    resetTree()
}

async function getTree() {
    const res = await store.fetchDepartmentsTree()
    departments.value = res
    resetTree()
}

function search() {
    deps.value = store.departments.filter(item => item.name.toLowerCase().includes(searchInput.value.toLowerCase()))
}

onMounted(async () => {
    await getTree()
    await store.fetchDepartments()
    department.value = departments.value[0]
    searchInput.value = department.value.name
})
</script>
