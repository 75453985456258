<template>
    <button class="text-xs p-2.5 semibold rounded"
        :class="{ 'bg-darkblue hover:bg-deepdarkblue text-white': type === 'primary', 'text-darkblue hover:text-deepdarkblue': type === 'secondary' }">
        <slot></slot>
    </button>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
    type: String
})

const type = ref()
type.value = props.type || 'primary' 
</script>
