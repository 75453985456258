<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" role="presentation">
        <g :fill="color">
            <slot />
        </g>
    </svg>
</template>
  
<script lang="ts">
export default {
    props: {
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    },
    computed: {
        viewBox() {
            return `0 0 ${this.width} ${this.height}`
        }
    }
}
</script>
