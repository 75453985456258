<template>
    <g clip-path="url(#clip0_4706_27727)">
        <path
            d="M7.96218 4.16644L10.3519 1.77669C10.5238 1.60484 10.7569 1.5083 11 1.5083C11.2431 1.5083 11.4762 1.60484 11.6481 1.77669L14.0378 4.16644H17.4167C17.6598 4.16644 17.893 4.26302 18.0649 4.43492C18.2368 4.60683 18.3333 4.83999 18.3333 5.0831V8.46194L20.7231 10.8517C20.8949 11.0236 20.9915 11.2567 20.9915 11.4998C20.9915 11.7428 20.8949 11.976 20.7231 12.1479L18.3333 14.5376V17.9164C18.3333 18.1596 18.2368 18.3927 18.0649 18.5646C17.893 18.7365 17.6598 18.8331 17.4167 18.8331H14.0378L11.6481 21.2229C11.4762 21.3947 11.2431 21.4912 11 21.4912C10.7569 21.4912 10.5238 21.3947 10.3519 21.2229L7.96218 18.8331H4.58335C4.34023 18.8331 4.10708 18.7365 3.93517 18.5646C3.76326 18.3927 3.66668 18.1596 3.66668 17.9164V14.5376L1.27693 12.1479C1.10508 11.976 1.00854 11.7428 1.00854 11.4998C1.00854 11.2567 1.10508 11.0236 1.27693 10.8517L3.66668 8.46194V5.0831C3.66668 4.83999 3.76326 4.60683 3.93517 4.43492C4.10708 4.26302 4.34023 4.16644 4.58335 4.16644H7.96218ZM5.50002 5.99977V9.22186L3.2221 11.4998L5.50002 13.7777V16.9998H8.7221L11 19.2777L13.2779 16.9998H16.5V13.7777L18.7779 11.4998L16.5 9.22186V5.99977H13.2779L11 3.72186L8.7221 5.99977H5.50002ZM11 15.1664C10.0276 15.1664 9.09492 14.7801 8.40729 14.0925C7.71966 13.4049 7.33335 12.4722 7.33335 11.4998C7.33335 10.5273 7.71966 9.59468 8.40729 8.90705C9.09492 8.21941 10.0276 7.8331 11 7.8331C11.9725 7.8331 12.9051 8.21941 13.5927 8.90705C14.2804 9.59468 14.6667 10.5273 14.6667 11.4998C14.6667 12.4722 14.2804 13.4049 13.5927 14.0925C12.9051 14.7801 11.9725 15.1664 11 15.1664ZM11 13.3331C11.4862 13.3331 11.9526 13.1399 12.2964 12.7961C12.6402 12.4523 12.8333 11.986 12.8333 11.4998C12.8333 11.0135 12.6402 10.5472 12.2964 10.2034C11.9526 9.85959 11.4862 9.66644 11 9.66644C10.5138 9.66644 10.0475 9.85959 9.70365 10.2034C9.35984 10.5472 9.16668 11.0135 9.16668 11.4998C9.16668 11.986 9.35984 12.4523 9.70365 12.7961C10.0475 13.1399 10.5138 13.3331 11 13.3331Z" />
    </g>
    <defs>
        <clipPath id="clip0_4706_27727">
            <rect width="22" height="22" fill="white" transform="translate(0 0.5)" />
        </clipPath>
    </defs>
</template>