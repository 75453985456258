<template>
    <div>
        <div class="relative">
            <div class="text-xs text-gray pb-1">
                <slot></slot>
            </div>
            <input :type="type"
                class="w-full h-[26px] text-sm max-w-lg rounded bg-grayxlight text-gray focus:bg-white focus:border-violet focus:outline-violet hover:border-gray border border-solid border-grayxlight px-3"
                :class="{ 'pl-8': iconLeft }" :value="modelValue"
                @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)">
            <icon v-if="iconLeft" class="absolute top-1 left-1">
                <slot name="left"></slot>
            </icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'text'
    },
    modelValue: {
        type: String,
        default: ''
    },
    iconLeft: {
        type: Boolean,
        default: false
    },
})
defineEmits(['update:modelValue'])

const { type } = toRefs(props);


</script>
