<template>
    <div>
        <div class="pb-2">
            <label class="text-xl text-gray">
                <slot></slot>
            </label>
        </div>
        <div class="relative">
            <input :type="inputType" class="w-full h-16 max-w-lg rounded"
                @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)">
            <icon v-if="type == 'password'" class="absolute top-5 right-5 cursor-pointer" @click="switchType">
                <icon-eye-x />
            </icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import IconEyeX from './icons/IconEyeX.vue'

const props = defineProps({
    type: {
        type: String,
        default: 'text'
    },
    modelValue: {
        type: String,
        default: ''
    }
})
defineEmits(['update:modelValue'])

const inputType = ref(props.type)

function switchType() {
    inputType.value = (inputType.value === 'string') ? 'password' : 'string'
}

</script>

<style scoped>
input {
    background-color: #f2f2f2;
    font-size: 22px;
    color: #1F1F1F;
    padding: 15px 20px;

    &:focus {
        outline-color: #d2d2d2;
    }
}
</style>