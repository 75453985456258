<template>
    <div class="rounded-full bg-grayxlight flex justify-center items-center"
        :class="[{ 'w-[33px] h-[33px]': size === 'sm' }, { 'w-[53px] h-[53px]': size === 'md' }]">{{ initials }}
    </div>
</template>

<script setup lang="ts">
type SizeType = 'sm' | 'md' | 'lg'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
    firstName: string,
    lastName: string,
    size?: SizeType
}>(), {
    size: 'sm' // Default value
});

const initials = computed(() => (props.lastName[0] + props.firstName[0]))
</script>
