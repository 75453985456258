import { createWebHistory, createRouter } from "vue-router"
import MainLayout from "@/layouts/MainLayout.vue"
import Schedule from "@/views/Schedule.vue"
import Employees from "@/views/Employees.vue"
import ShiftExchange from "@/views/ShiftExchange.vue"
import Analytics from "@/views/Analytics.vue"

const routes = [
  {
    path: "/",
    name: 'Home',
    meta: { layout: MainLayout },
    component: Schedule,
  },
  {
    path: "/employees",
    name: 'Employees',
    meta: { layout: MainLayout },
    component: Employees,
  },
  {
    path: "/shift-exchange",
    name: 'ShiftExchange',
    meta: { layout: MainLayout },
    component: ShiftExchange,
  },
  {
    path: "/analytics",
    name: 'Analytics',
    meta: { layout: MainLayout },
    component: Analytics,
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE),
  routes,
});



export default router;