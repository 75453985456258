<template>
    <div
        class="absolute hidden group-hover:block bg-white p-4 text-left rounded shadow-lg top-10 left-0 z-50 whitespace-nowrap text-[11px]">
        <div class="mb-2 text-darkgray">Основной табель: 10 ч.</div>
        <div class="mb-2 text-gray text-[10px]">Продавец-консультант 10:00-21:00</div>
        <div class="mb-2 text-darkgray">Дополнительный табель: 1 ч.</div>
        <div class="mb-2 text-gray text-[10px]">Продавец-консультант 10:00-21:00</div>
        <div class="text-darkgray">Всего рабочих часов: 11 ч.</div>
    </div>
</template>
