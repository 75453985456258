<template>
    <div>
        <div class="flex justify-between border-b border-grayxlight mb-3">
            <div class="text-[13px] semibold text-darkgray">Копирование смены</div>
            <div class="text-xs text-graytextlight cursor-pointer" @click="isOpen = !isOpen">
                <span v-if="isOpen">Свернуть</span>
                <span v-else>Развернуть</span>
            </div>
        </div>
        <div v-if="isOpen">
            <div class="text-gray text-xs mb-3">Выберите дни для копирования</div>
            <VDatePicker v-model.range="range" color="blue" locale="ru" mode="date" @dayclick="onDayClick()"
                borderless />
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue'
import { useStore } from '../../stores/store'

const store = useStore()
const emit = defineEmits(['on-copy'])

const isOpen = ref(false)


const range = ref<{ start: Date, end: Date }>()

function onDayClick() {
    if (range.value)
        copy(store.currentShift, +dayjs(range.value.start).format('D'), +dayjs(range.value.end).format('D'))
}

function copy(shift: any, start: any, end: any) {
    const shifts = []
    const startTime = dayjs(shift.plan_work_start_at).format('HH:mm')
    const endTime = dayjs(shift.plan_work_end_at).format('HH:mm')
    for (let index = start; index <= end; index++) {
        const date = dayjs(shift.date).set('date', index).format('YYYY-MM-DD')
        const tempShift = {
            date,
            employment: shift.employment,
            department: shift.department,
            position: shift.position.id,
            day_type: shift.day_type.id,
            is_overtime: shift.is_overtime,
            is_vacancy: shift.is_vacancy,
            plan_work_start_at: dayjs(`${date} ${startTime}`).toISOString(),
            plan_work_end_at: dayjs(`${date} ${endTime}`).toISOString()
        }
        shifts.push(tempShift)

    }
    emit('on-copy', shifts)
}

</script>
<style>
.vc-blue {
    font-weight: 400 !important;
    color: #333 !important;
}

.vc-highlight-bg-solid {
    border-radius: 50% !important;
    border: 1px solid #AEDEE2 !important;
    background: none !important;
    color: #666 !important;
}

.vc-highlight-bg-light {
    background: none !important;
}

.vc-highlight-content-light {
    background: #f2f2f2;
}

.vc-title {
    font-weight: 400 !important
}
</style>