<template>
    <g clip-path="url(#clip0_4706_27728)">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.511 5.5H16.1C15.9659 4.84007 15.9659 4.15993 16.1 3.5H3C2.73478 3.5 2.48043 3.60536 2.29289 3.79289C2.10536 3.98043 2 4.23478 2 4.5V20.5C2 20.7652 2.10536 21.0196 2.29289 21.2071C2.48043 21.3946 2.73478 21.5 3 21.5H21C21.2652 21.5 21.5196 21.3946 21.7071 21.2071C21.8946 21.0196 22 20.7652 22 20.5V9.4C21.3401 9.53406 20.6599 9.53406 20 9.4V19.5H4V7.716L12.072 14.838L18.673 8.927C18.068 8.608 17.536 8.169 17.11 7.642L12.061 12.162L4.511 5.5Z" />
        <path
            d="M21 7.5C20.606 7.5 20.2159 7.4224 19.8519 7.27164C19.488 7.12087 19.1573 6.8999 18.8787 6.62132C18.6001 6.34274 18.3791 6.01203 18.2284 5.64805C18.0776 5.28407 18 4.89397 18 4.5C18 4.10603 18.0776 3.71593 18.2284 3.35195C18.3791 2.98797 18.6001 2.65726 18.8787 2.37868C19.1573 2.1001 19.488 1.87913 19.8519 1.72836C20.2159 1.5776 20.606 1.5 21 1.5C21.7956 1.5 22.5587 1.81607 23.1213 2.37868C23.6839 2.94129 24 3.70435 24 4.5C24 5.29565 23.6839 6.05871 23.1213 6.62132C22.5587 7.18393 21.7956 7.5 21 7.5Z"
            fill="#B43838" />
    </g>
    <defs>
        <clipPath id="clip0_4706_27728">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
    </defs>
</template>