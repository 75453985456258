<template>
    <div class="flex justify-between items-center py-3 pl-7 pr-7 text-[13px]">
        <div class="flex">
            <div class="pr-3">
                <DepartmentsTree :current="department" class="z-[51]" />
            </div>
            <div>
                <Months />
            </div>
        </div>
        <div class="flex items-center">
            <Switcher class="mr-2" />
            <div class="px-3">
                <button-icon>
                    <IconStar />
                    <template v-slot:hover>
                        <IconStarColor />
                    </template>
                </button-icon>
            </div>
            <div class="px-3">
                <button-icon>
                    <IconDiagram />
                    <template v-slot:hover>
                        <IconDiagramColor />
                    </template>
                </button-icon>
            </div>
            <div class="px-3">
                <button-icon>
                    <IconOptions />
                    <template v-slot:hover>
                        <IconOptionsColor />
                    </template>
                </button-icon>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
import IconOptions from '../../ui/icons/IconOptions.vue'
import IconDiagram from '../../ui/icons/IconDiagram.vue'
import IconStar from '../../ui/icons/IconStar.vue'
import Months from './Months.vue'
import Switcher from './Switcher.vue'
import IconDiagramColor from '../../ui/icons/IconDiagramColor.vue'
import IconStarColor from '../../ui/icons/IconStarColor.vue'
import IconOptionsColor from '../../ui/icons/IconOptionsColor.vue'
import DepartmentsTree from './DepartmentsTree.vue'

const department = { id: 'e03425ca-df08-4e8a-a1aa-459aac29793b', name: 'Ромашка' }


</script>
