<template>
    <div class="relative cursor-pointer">
        <div class="selected-option" @click="toggleDropdown">
            <slot></slot>
        </div>
        <div v-if="isOpen" class="options overflow-y-auto z-10 shadow-lg rounded" v-click-outside="toggleDropdown">
            <div v-for="option in options" :key="option.value"
                class="flex items-center p-2 text-gray hover:bg-grayxlight" @click="selectOption(option)">
                <i class="rounded-full border-2 border-gray min-w-4 h-4 inline-block mr-2"
                    :class="{ 'selected': selected?.value === option.value }"></i>
                <span :class="{ 'text-darkblue': selected?.value === option.value }">{{ option.title }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface Option {
    value: string;
    title: string;
}

interface Props {
    options: Option[]
}

const props = defineProps<Props>()
const selected = defineModel<Option>()

selected.value = props.options[0]
const isOpen = ref(false)

const toggleDropdown = () => {
    isOpen.value = !isOpen.value
};

const selectOption = (option: Option) => {
    selected.value = option
    isOpen.value = false
};
</script>

<style scoped>
.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    width: 320px;
    font-size: 14px
}

.selected {
    border-color: #211A4D;
    position: relative;
}

.selected::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #211A4D;
    border-radius: 50%;
    display: block;
    top: 2px;
    left: 2px;
}
</style>