<template>
    <div class="relative cursor-pointer w-full">
        <div class="w-full h-[26px] rounded bg-grayxlight pl-3 pr-3 text-sm text-gray flex items-center"
            @click="toggleDropdown">
            <slot></slot>
        </div>
        <div v-if="isOpen"
            class="absolute top-full left-0 right-0 overflow-y-auto z-10 shadow-lg rounded bg-white text-sm"
            v-click-outside="toggleDropdown">
            <div v-for="option in options" :key="option.id" class="p-2 text-gray hover:bg-grayxlight"
                @click="selectOption(option)">
                <span :class="{ 'text-darkblue ': selected?.id === option.id }">{{ option.name
                    }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Option } from '../types'

interface Props {
    options: Option[]
}

const { options } = defineProps<Props>()
const selected = defineModel<Option>()

const isOpen = ref(false)

const toggleDropdown = () => {
    isOpen.value = !isOpen.value
};

const selectOption = (option: Option) => {
    selected.value = option
    isOpen.value = false
};
</script>

<style scoped>
.selected {
    border-color: #211A4D;
    position: relative;
}
</style>