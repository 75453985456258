<template>
    <div>
        <div>
            <div class="flex justify-between mb-4">
                <div class="border-b border-grayxlight text-[13px]">Основные данные</div>
                <button-icon class="text-darkred opacity-40">
                    <IconTrash />
                </button-icon>
            </div>

            <div class="flex mb-4">
                <div class="mr-5 flex-1">
                    <div class="text-xs text-gray pb-1">Тип дня</div>
                    <dropdown v-model="dayType" :options="store.dayTypes">
                        {{ dayType.name }}
                    </dropdown>
                </div>
                <div class="mr-5 flex-1">
                    <div class="text-xs text-gray pb-1">Должность</div>
                    <dropdown v-model="position" :options="store.positions">
                        {{ position.name }}
                    </dropdown>
                </div>
            </div>

            <checkbox class="text-sm" v-model="isOvertime">Дополнительный табель</checkbox>

            <div class="mb-4">
                <div class="flex justify-between mb-3">
                    <div class="border-b border-grayxlight text-[13px]">Плановая смена</div>
                </div>

                <div class="flex">
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время начала</div>
                        <time-picker v-model="start" :minute-interval="5" />
                    </div>
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время окончания</div>
                        <time-picker v-model="end" :minute-interval="5" />
                    </div>
                </div>
            </div>

            <div v-if="startFact" class="mb-2">
                <div class="flex justify-between mb-3">
                    <div class="border-b border-grayxlight text-[13px]">Фактическая смена</div>
                </div>

                <div class="flex">
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время начала</div>
                        <time-picker v-model="startFact" :minute-interval="5" />
                    </div>
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время окончания</div>
                        <time-picker v-model="endFact" :minute-interval="5" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Shift } from '../../types'
import IconTrash from '../../ui/icons/IconTrash.vue'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TimePicker from 'vue3-timepicker'
import { Option } from '../../types'

dayjs.extend(utc)

import { useStore } from '../../stores/store'

const store = useStore()

interface Props {
    shift: Shift
}

const props = defineProps<Props>()
const shift = ref<Shift>(props.shift)
const emit = defineEmits(['change'])
const isOvertime = ref(shift.value.is_overtime)
const start = ref()
const end = ref()
const startFact = ref<string | null>(null)
const endFact = ref<string | null>(null)

const dayType = ref<Option>({ id: shift.value.day_type.id, name: shift.value.day_type.name })
const position = ref<Option>({ id: shift.value.position.id, name: shift.value.position.name })

start.value = dayjs.utc(shift.value.plan_work_start_at).format('HH:mm')
end.value = dayjs.utc(shift.value.plan_work_end_at).format('HH:mm')

if (shift.value.fact_work_start_at) {
    startFact.value = dayjs.utc(shift.value.fact_work_start_at).format('HH:mm')
    endFact.value = dayjs.utc(shift.value.fact_work_end_at).format('HH:mm')
}

watch([position, dayType, start, end, startFact, endFact, isOvertime], () => {
    shift.value.position = position.value
    shift.value.day_type = dayType.value
    shift.value.plan_work_start_at = dayjs.utc(`${shift.value.date} ${start.value}`).toISOString()
    shift.value.plan_work_end_at = dayjs.utc(`${shift.value.date} ${end.value}`).toISOString()
    shift.value.fact_work_start_at = (shift.value.fact_work_start_at) ? dayjs.utc(`${shift.value.date} ${startFact.value}`).toISOString() : null
    shift.value.fact_work_end_at = (shift.value.fact_work_end_at) ? dayjs.utc(`${shift.value.date} ${endFact.value}`).toISOString() : null
    shift.value.is_overtime = isOvertime.value
    emit('change', shift.value)

})

</script>
