<template>
    <ul class="flex text-gray">
        <li class="px-3.5"><router-link to="/">{{ $t('menu.schedule') }}</router-link></li>
        <li class="px-3.5"><router-link to="/employees">{{ $t('menu.employees') }}</router-link></li>
        <li class="px-3.5"><router-link to="/shift-exchange">{{ $t('menu.shift_exchange') }}</router-link></li>
        <li class="px-3.5"><router-link to="/analytics">{{ $t('menu.analytics') }}</router-link></li>
    </ul>
</template>

<script setup lang="ts">

</script>

<style scoped>
.router-link-active {
    text-decoration: underline;
}
</style>