<template>
    <div class="w-6 h-6 flex justify-center items-center cursor-pointer rounded relative">
        <icon :width="width" :height="height">
            <slot></slot>
        </icon>

        <icon v-if="$slots.hover" :width="width" :height="height" class="absolute opacity-0 hover:opacity-100">
            <slot name="hover"></slot>
        </icon>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        }
    }
}
</script>