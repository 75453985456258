<template>
    <div>
        <div class="flex border-b border-grayxlight mb-4 text-[11px]">
            <div class=" text-darkblue mr-4 cursor-pointer leading-4" v-for="( shift, index) in shifts" :key="shift.id"
                :class="{ 'semibold text-[13px] border-b border-x-darkblue': (index === currentShiftIndex) }"
                @click="switchTab(index)">Смена {{ index + 1 }}</div>
            <div class="text-[11px] text-gray cursor-pointer" @click="addTab">+ Добавить смену</div>
        </div>
        <div v-for="(shift, index) in shifts" :key="index">
            <ShiftsItem :shift v-if="index == currentShiftIndex" @change="onShiftChange" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Shift } from '../../types'
import ShiftsItem from './ShiftsItem.vue'
import dayjs from 'dayjs'

import { useStore } from '../../stores/store'

const store = useStore()

interface Props {
    shifts: Shift[],
    date: string
}

const props = defineProps<Props>()

const shifts = ref<Shift[]>(props.shifts)

const currentShiftIndex = ref<number>(0)

const addTab = () => {
    let overtime = false
    if (shifts.value.length) {
        overtime = true
    }
    shifts.value.push({
        day_type: store.dayTypes[0],
        position: store.positions[0],
        plan_work_start_at: dayjs.utc(props.date).toISOString(),
        plan_work_end_at: dayjs.utc(props.date).toISOString(),
        fact_work_start_at: null,
        fact_work_end_at: null,
        is_overtime: overtime,
        date: props.date
    })
    switchTab(shifts.value.length - 1)
}

const switchTab = (index: number) => {
    currentShiftIndex.value = index
    store.setCurrentShift(shifts.value[index])
}

const onShiftChange = (shift: Shift) => {
    store.setCurrentShift(shift)
}
</script>
