<template>
    <div class="flex justify-between items-center pl-3">
        <div class="w-6 h-6 flex justify-center items-center cursor-pointer" @click="store.prevScheduleMonth()">
            <icon width="9" height="13">
                <IconChevronLeft />
            </icon>
        </div>
        <div class="rounded bg-grayxlight h-[26px] w-24 text-center pt-1 first-letter:uppercase">
            {{ month }}
        </div>
        <div class="w-6 h-6 flex justify-center items-center cursor-pointer" @click="store.nextScheduleMonth()">
            <icon width="9" height="13">
                <IconChevronRight />
            </icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import IconChevronLeft from '../../ui/icons/IconChevronLeft.vue'
import IconChevronRight from '../../ui/icons/IconChevronRight.vue'
import { useStore } from '@/stores/store'


const store = useStore()

const month = computed(() => store.currentScheduleDate.format('MMMM'))

</script>
