<template>
    <div>
        <div @click="handleClick" class="text-gray hover:bg-grayxlight px-2 py-1">
            {{ node?.name }}
        </div>
        <div v-if="children" class="ml-5 border-l border-l-grayxlight">
            <TreeNode v-for="child in children" :key="child.id" :node="child" @node-clicked="handleChildClick" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    node: {
        [key: string]: any;
    }
}

const { node } = defineProps<Props>()

const emit = defineEmits(['node-clicked'])

const handleClick = () => {
    emit('node-clicked', node)
}

const handleChildClick = (childNode: Node) => {
    emit('node-clicked', childNode)
}

const children = computed(() => node.children)

</script>
