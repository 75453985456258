<template>
    <g clip-path="url(#clip0_3604_5033)">
        <path
            d="M17.882 19.2969C16.1232 20.4125 14.0828 21.0033 12 20.9999C6.60803 20.9999 2.12203 17.1199 1.18103 11.9999C1.61106 9.67066 2.78266 7.54284 4.52103 5.93394L1.39203 2.80794L2.80703 1.39294L22.606 21.1929L21.191 22.6069L17.881 19.2969H17.882ZM5.93503 7.34994C4.57604 8.58553 3.62935 10.2087 3.22303 11.9999C3.53532 13.3664 4.16229 14.6411 5.05403 15.7225C5.94577 16.804 7.07766 17.6623 8.35958 18.2293C9.64151 18.7962 11.0381 19.056 12.4381 18.9881C13.8382 18.9201 15.203 18.5263 16.424 17.8379L14.396 15.8099C13.5327 16.3538 12.5102 16.588 11.4963 16.4744C10.4823 16.3607 9.53707 15.9058 8.8156 15.1844C8.09413 14.4629 7.63926 13.5177 7.52559 12.5037C7.41193 11.4897 7.64621 10.4673 8.19003 9.60394L5.93503 7.34994ZM12.914 14.3279L9.67203 11.0859C9.49409 11.5389 9.45222 12.0339 9.55154 12.5103C9.65085 12.9867 9.88705 13.4237 10.2311 13.7678C10.5752 14.1119 11.0123 14.3481 11.4887 14.4474C11.9651 14.5468 12.4601 14.5049 12.913 14.3269L12.914 14.3279ZM20.807 16.5919L19.376 15.1619C20.0445 14.2092 20.5204 13.1351 20.777 11.9999C20.5053 10.8097 19.9943 9.68709 19.2752 8.70044C18.5561 7.71379 17.6438 6.88367 16.5939 6.26055C15.544 5.63743 14.3783 5.23431 13.1678 5.07571C11.9572 4.9171 10.727 5.00632 9.55203 5.33794L7.97403 3.75994C9.22103 3.26994 10.58 2.99994 12 2.99994C17.392 2.99994 21.878 6.87994 22.819 11.9999C22.5126 13.6656 21.8239 15.2375 20.807 16.5919ZM11.723 7.50794C12.3595 7.46861 12.9971 7.56501 13.5936 7.79076C14.19 8.01651 14.7316 8.36646 15.1826 8.81739C15.6335 9.26833 15.9835 9.80997 16.2092 10.4064C16.435 11.0028 16.5314 11.6404 16.492 12.2769L11.722 7.50794H11.723Z"
            fill="#606060" />
    </g>
    <defs>
        <clipPath id="clip0_3604_5033">
            <rect width="24" height="24" fill="white" />
        </clipPath>
    </defs>
</template>